import { combineReducers } from "redux";
import { ADD_CLEANING_SERVICES, CleaningServiceActionType, CleaningServiceState, SET_CLEANING_SERVICES, START_LOADING_CLEANING, STOP_LOADING_CLEANING } from "../../../models";

const initialState: CleaningServiceState = {}

function getUniqueElements(arr: string[]) {
  let uniqueArr = [];
  
  for (let i = 0; i < arr.length; i++) {
    if (uniqueArr.indexOf(arr[i]) === -1) {
      uniqueArr.push(arr[i]);
    }
  }
  
  return uniqueArr;
}

export default combineReducers({
  data: (state: CleaningServiceState = initialState, action: CleaningServiceActionType) => {
    switch(action.type) {
      case SET_CLEANING_SERVICES:
        state = action.payload;
        return state;
      case ADD_CLEANING_SERVICES:
        state = { 
          ranges: [ ...state.ranges, action.payload.ranges[0] ],
          prices: { ...state.prices, [action.payload.ranges[0]]: action.payload.prices[action.payload.ranges[0]] },
          payment_modes: getUniqueElements([...state.payment_modes, ...action.payload.payment_modes]),
          services: {
            ...state.services,
            ...action.payload.services,
          }
        };
        return state;
      default:
        return state;
    }
  },
  isLoading: (state = false, action: CleaningServiceActionType) => {
    switch (action.type) {
      case START_LOADING_CLEANING:
          return true;

      case STOP_LOADING_CLEANING:
          return false;

      default:
          return state;
    }
  }
})