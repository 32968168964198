export const SET_UNLIMITED_SESSION_PACKAGE_DATA = "SET_UNLIMITED_SESSION_PACKAGE_DATA";
export const ADD_UNLIMITED_SESSION_PACKAGE_DATA = "ADD_UNLIMITED_SESSION_PACKAGE_DATA";
export const UPDATE_UNLIMITED_SESSION_PACKAGE_STATUS = "UPDATE_UNLIMITED_SESSION_PACKAGE_STATUS";
export const UPDATE_UNLIMITED_SESSION_PACKAGE_DATA = "UPDATE_UNLIMITED_SESSION_PACKAGE_DATA";
export const DELETE_UNLIMITED_SESSION_PACKAGE = "DELETE_UNLIMITED_SESSION_PACKAGE";
export const SET_UNLIMITED_SESSION_PACKAGE_PAGINATION="SET_UNLIMITED_SESSION_PACKAGE_PAGINATION";

export interface UnlimitedSessionPackageState {
  [key: string]: any;
}

export interface UnlimitedSessionPackagePaginationState {
  [key: string]: any;
}

interface SetUnlimitedSessionPackages {
  type: typeof SET_UNLIMITED_SESSION_PACKAGE_DATA;
  payload: UnlimitedSessionPackageState[]
}

interface AddUnlimitedSessionPackage {
  type: typeof ADD_UNLIMITED_SESSION_PACKAGE_DATA;
  payload: UnlimitedSessionPackageState[]
}

interface UpdateUnlimitedSessionPackageStatus {
  type: typeof UPDATE_UNLIMITED_SESSION_PACKAGE_STATUS;
  payload: UnlimitedSessionPackageState
}

interface UpdateUnlimitedSessionPackage {
  type: typeof UPDATE_UNLIMITED_SESSION_PACKAGE_DATA;
  payload: UnlimitedSessionPackageState
}

interface DeleteUnlimitedSessionPackage {
  type: typeof DELETE_UNLIMITED_SESSION_PACKAGE;
  payload: number
}

interface SetUnlimitedSessionPackagePagination {
  type: typeof SET_UNLIMITED_SESSION_PACKAGE_PAGINATION;
  payload: UnlimitedSessionPackagePaginationState
}

export type UnlimitedSessionPackageActionType =
  | SetUnlimitedSessionPackages
  | AddUnlimitedSessionPackage
  | UpdateUnlimitedSessionPackageStatus
  | UpdateUnlimitedSessionPackage
  | DeleteUnlimitedSessionPackage;

export type UnlimitedSessionPackagePaginationActionType =
  | SetUnlimitedSessionPackagePagination;