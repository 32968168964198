import {ThunkAction} from "redux-thunk";
import {AppState} from "../../reducers";
import {Action} from "redux";
import {startLoading, stopLoading} from "..";
import {PayoutModel, SET_FAILELD, SET_SESSIONS_PAYOUT, START_LOADING, STOP_LOADING, SET_ALL_PAYOUT, SET_URL_RECEIPT} from "../../../models";
import {adjustmentModel, GET_ADJUSTMENT, SET_ADJUSTMENT} from "../../../models/app/payments";
import {getAdjustmentApi, setAdjustmentApi, setSessionsPayoutApi, getAllPaymentsApi, getUrlReceiptApi, getRoyaltiesApi, postSalesReportApi, postCreatePaymentMethodApi} from "../../../api/payments";


export const getAdjustmentAction = (page:number, perPage:number): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await getAdjustmentApi(page,perPage).xhr;

            // set account info
            if (response) {
                dispatch({type: GET_ADJUSTMENT, payload: response})
                return true;
            }

        } catch (err) {
            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})
            return false;

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};


export const setAdjustmentAction = (body:adjustmentModel): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await setAdjustmentApi(body).xhr;

            // set account info
            if (response) {
                dispatch({type: SET_ADJUSTMENT, payload: response})
                return true;
            }

        } catch (err) {
            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})
            return false;

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const setSessionsPayoutAction = (body: PayoutModel): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await setSessionsPayoutApi(body).xhr;

            // set account info
            if (response) {
                dispatch({type: SET_SESSIONS_PAYOUT, payload: response})
                return true;
            }

        } catch (err) {
            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})
            return false;

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const getAllPaymentsAction = (id: string, isMasterFranchise: boolean, filters:any, page:number): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await getAllPaymentsApi(id, isMasterFranchise, filters, page).xhr;

            // set payment all
            if (response) {
                dispatch({type: SET_ALL_PAYOUT, payload: response})
                return true;
            }

        } catch (err) {
            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})
            return false;

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const getUrlReceiptAction = (item: any): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await getUrlReceiptApi(item).xhr;

            // set receipt url
            if (response) {
                dispatch({type: SET_URL_RECEIPT, payload: response})
                return true;
            }

        } catch (err) {
            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})
            return false;

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};


export const getTransactionsAction = (page: number, perPage: number): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await getAdjustmentApi(page, perPage).xhr;

            // set account info
            if (response) {
                dispatch({type: GET_ADJUSTMENT, payload: response})
                return true;
            }

        } catch (err) {
            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})
            return false;

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};


export const getRoyaltiesAction = (params:any): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await getRoyaltiesApi(params).xhr;
            if (response) {
                return response;
            }

        } catch (err) {
            dispatch({type: SET_FAILELD, payload: {message: err}})
            return false;

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const postSalesReportsAction = (franchise_id:number, body:any): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await postSalesReportApi(franchise_id, body).xhr;
            if (response) {
                return response;
            }
        } catch (err) {
            dispatch({type: SET_FAILELD, payload: {message: err}})
            return false;
        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const postCreatePaymentMethodAction = (body:any): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await postCreatePaymentMethodApi(body).xhr;
            if (response) {
                return response;
            }
            return false;
        } catch (err) {
            dispatch({type: SET_FAILELD, payload: {message: err}})
        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};
