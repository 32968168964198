import axios from 'axios';

export const getCleaningServicesApi = (franchiseId:string) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, string>({
      method: "get",
      url: `/cleaning-services/list-all-prices/${franchiseId}`,
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export const createCleaningServicePackagesApi = (data: {[key:string]: string|number}[]) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, string>({
      method: "post",
      url: `/cleaning-services/package-create`,
      data
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export const updateCleaningServicePackageApi = (data: {[key:string]: string|number}[]) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, string>({
      method: "put",
      url: `/cleaning-services/update-packages`,
      data
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export const updateStatus = () => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, string>({
      method: "put",
      url: `/cleaning-services/change-status/4/archived`,
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export const doCleaningServiceCheckoutApi = (body:any) =>{
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, string>({
      method: "post",
      url: `/cleaning-service-payment`,
      data: body
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}