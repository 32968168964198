import axios from 'axios';

export const getLawnPricesAndDiscountsApi = (franchiseId:string) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, string>({
      method: "get",
      url: `/lawn-services/get-prices-and-discount/${franchiseId}`,
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export const createLawnServicePricesApi = (data: {[key:string]: string|number}[]) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, string>({
      method: "post",
      url: `/lawn-services/prices-create`,
      data
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export const createLawnServiceDiscountsApi = (data: {[key:string]: string|number}[]) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, string>({
      method: "post",
      url: `/lawn-services/discounts-create`,
      data
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}


export const updateLawnServicePricesApi = (data: {[key:string]: string|number}[]) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, string>({
      method: "put",
      url: `/lawn-services/update-prices`,
      data
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export const updateLawnServiceDiscountsApi = (data: {[key:string]: string|number}[]) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, string>({
      method: "put",
      url: `/lawn-services/update-discounts`,
      data
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}