const initialFormState: any = {
    forms: [],
  };
  
  const formReducer = (
    state: any = initialFormState,
    action: { type: string; payload: any }
  ) => {
    switch (action.type) {
      case "SET_FORMS": {
        return {
          ...state,
          forms: action.payload,
        };
      }
      default:
        return state;
    }
  };
  
  export default formReducer