
export const SET_SPECIALITIES = "SET_SPECIALITIES";
export const SET_SPECIALITIES_WITH_CATEGORIES = "SET_SPECIALITIES_WITH_CATEGORIES";
export const UPDATE_SPECIALITIES_WITH_CATEGORIES = "UPDATE_SPECIALITIES_WITH_CATEGORIES";
export const SET_SPECIALITIES_FOR_FILTER = "SET_SPECIALITIES_FOR_FILTER";
export const ADD_SPECIALITIES = "ADD_SPECIALITIES";


export interface SpecialtiesModal {
    id: number;
    name: string;
    status: boolean;
    Speciality:any;
}
export interface SpecialtiesWithCategoriesModal {
    id: number;
    name: string;
    status: boolean;
    Speciality:any;
    categories: any;
}
export interface UpdateSpecialtiesWithCategoriesModal {
    id: number;
}

interface SetSpecialties {
    type: typeof SET_SPECIALITIES;
    payload: SpecialtiesModal[];
}
interface SetSpecialtiesWithCategories {
    type: typeof SET_SPECIALITIES_WITH_CATEGORIES;
    payload: SpecialtiesWithCategoriesModal[];
}
interface updateSpecialtiesWithCategories {
    type: typeof UPDATE_SPECIALITIES_WITH_CATEGORIES;
    payload: UpdateSpecialtiesWithCategoriesModal;
}

interface SetSpecialtiesForFilter {
    type: typeof SET_SPECIALITIES_FOR_FILTER;
    payload: SpecialtiesModal[];
}

interface AddSpecialties {
    type: typeof ADD_SPECIALITIES;
    payload: SpecialtiesModal[];
}


export type SpecialtiesActionType = SetSpecialties | SetSpecialtiesForFilter | AddSpecialties | SetSpecialtiesWithCategories | updateSpecialtiesWithCategories;
