import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { AppState } from "../../reducers";
import { startLoading, stopLoading } from "../common/loading";
import { fileUploadApi, fileUploadToServerApi } from "../../../api";
import { getUserProfileAction } from "./account";
import {
  SET_FAILELD,
  SET_SUCCESS,
  START_LOADING,
  STOP_LOADING,
} from "../../../models";

export const fileUploadAction = (
  path: string,
  body: FormData,
  onUploadProgress: (data: any)=> {},
  enableGlobalLoader: boolean = true,
): ThunkAction<void, AppState, null, Action<string>> => {
  return async (dispatch: any, getState: any) => {
    try {
      if(enableGlobalLoader) startLoading(START_LOADING)(dispatch, getState, null);
      const response = await fileUploadApi(path, body, onUploadProgress).xhr;
      if (response) {
        return response;
      } else {
        dispatch({
          type: SET_FAILELD,
          payload: { message: "Something went wrong while uploading." },
        });
        return false;
      }
    } catch (err) {
      // show error
      dispatch({
        type: SET_FAILELD,
        payload: { message: "Something went wrong while uploading image." },
      });
      return false;
    } finally {
      if(enableGlobalLoader) stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

export const uploadImageToServerAction = (
  body: FormData,
  baseUrl: 'string'
): ThunkAction<void, AppState, null, Action<string>> => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await fileUploadToServerApi(body, baseUrl).xhr;
      if (response) {
        return response;
      } else {
        dispatch({
          type: SET_FAILELD,
          payload: { message: "Something went wrong while uploading." },
        });
        return false;
      }
    } catch (err) {
      // show error
      dispatch({
        type: SET_FAILELD,
        payload: { message: "Something went wrong while uploading image." },
      });
      return false;
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};
