import axios from "axios";
import {
    FranchiseState,
    FranchiseParams, updateAdminFranchiseList, FranchiseInfoState
} from "../models";
var qs =  require('qs');

export function franchiseListByCity(cityId: string ,config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, FranchiseState>({
        method: "get",
        url: `/franchise/by/city/${cityId}`,
        paramsSerializer: params => {
            return qs.stringify(params)
        }
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function getListFranchise(cityId: string ,hasAdmin:any,config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    let params:any={

    };
    if(hasAdmin!==''){
        params.hasAdmin=hasAdmin;
    }
    if(cityId!==''){
        params.city=cityId;
    }
    const xhr = axios.request<null, FranchiseState>({
        method: "get",
        url: `/franchise`,
        params:params,
        paramsSerializer: params => {
            return qs.stringify(params)
        }
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function getListTimezones(config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, FranchiseState>({
        method: "get",
        url: `/timezone`,
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function getFranchiseById(franchiseId: string ,config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, FranchiseState>({
        method: "get",
        url: `/franchise/${franchiseId}`,
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function addFranchiseApi(params: FranchiseParams ,config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, FranchiseState>({
        method: "post",
        url: `/franchise`,
        data: params
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function editFranchiseApi(id: string, params: FranchiseParams ,config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, FranchiseState>({
        method: "put",
        url: `/franchise/${id}`,
        data: params
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}
export function setFranchiseInfoAPI(id: string, params: FranchiseInfoState ,config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, FranchiseState>({
        method: "post",
        url: `/franchiseInfo/${id}`,
        data: params
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}
export function deleteFranchiseApi(id: string ,config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, FranchiseState>({
        method: "delete",
        url: `/franchise/${id}`
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function getUserFranchiseBaseRoleApi(config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, FranchiseState>({
        method: "get",
        url: `/franchiseRole`
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function getFranchiseBaseCountry(country_id = 1) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, FranchiseState>({
        method: "get",
        url: `/franchise/by/country/${country_id}`
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}


export function updateAdminFranchiseApi(body: updateAdminFranchiseList ,config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, updateAdminFranchiseList>({
        method: "post",
        url: `/franchiseAdmin`,
        data:body
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function getFranchiseReviewApi(userId: string) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, FranchiseState>({
        method: "get",
        url: `/franchise/review/switch/${userId}`,
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}



export function postUpdateFranchiseAutoRenewApi(franchise_id:number, status:boolean) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, updateAdminFranchiseList>({
        method: "post",
        url: `/update-franchise-auto-renew`,
        data: {franchise_id, status}
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}


export function postUpdateFranchiseSettingsApi(franchise_id:number, data:any) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, updateAdminFranchiseList>({
        method: "post",
        url: `/update-franchise-settings`,
        data: {franchise_id, data}
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function getFranchisePbApi(franchise_id:number) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, updateAdminFranchiseList>({
        method: "get",
        url: `/get-stripe-pb/${franchise_id}`
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function getFranchisePublishableKeyApi(franchise_id:number) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, FranchiseState>({
        method: "get",
        url: `/get-stripe-pb/${franchise_id}`,
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}
