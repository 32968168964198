import { ThunkAction } from 'redux-thunk'
import { Action } from 'redux'
import { AppState } from '../../reducers'
import {
    //models
    SpecialtiesModal,

    // constants package
    SET_SPECIALITIES,
    SET_SPECIALITIES_FOR_FILTER,
    ADD_SPECIALITIES,

    // loading
    START_LOADING,
    STOP_LOADING,

    // constants alert
    SET_FAILELD,
    SET_SUCCESS,
    SET_SPECIALITIES_WITH_CATEGORIES,
    UPDATE_SPECIALITIES_WITH_CATEGORIES
    
} from "../../../models";
import {
    getListSpecialiesApi, getListSpecialiesForFilterApi, addSpecialitiesApi, addSpecialityWithCategoryApi, getSpecialitiesWithCategoryApi, updateSpecialitiesWithCategoryApi, deleteSpecialityByidApi
} from '../../../api';
import {
    startLoading,
    stopLoading
} from '../index'



// get list category
export const getSpecialitiesAction = (): ThunkAction<void, AppState, null, Action<string>> => {
    return async(dispatch: any, getState: any) => {

        try{
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await getListSpecialiesApi().xhr;

            // set account info
            if( response ){
                dispatch({type: SET_SPECIALITIES, payload: response})
            }
            
        }catch(err){

            // show error
            dispatch({type: SET_FAILELD, payload: { message: err }})
            
        }finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const getSpecialitiesWithCategoryAction = (): ThunkAction<void, AppState, null, Action<string>> => {
    return async(dispatch: any, getState: any) => {

        try{
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await getSpecialitiesWithCategoryApi().xhr;

            // set account info
            if( response ){
                dispatch({type: SET_SPECIALITIES_WITH_CATEGORIES, payload: response})
            }
            
        }catch(err){

            // show error
            dispatch({type: SET_FAILELD, payload: { message: err }})
            
        }finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const updateSpecialitiesWithCategoryAction = (speciality_id: number, data: any): ThunkAction<void, AppState, null, Action<string>> => {
    return async(dispatch: any, getState: any) => {

        try{
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await updateSpecialitiesWithCategoryApi(speciality_id, data).xhr;
            return response;
        }catch(err){
            // show error
            dispatch({type: SET_FAILELD, payload: { message: err }})
            return err;
            
        }finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

// get list speciality for filter
export const getSpecialitiesForFilterAction = (): ThunkAction<void, AppState, null, Action<string>> => {
    return async(dispatch: any, getState: any) => {

        try{
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await getListSpecialiesForFilterApi().xhr;

            // set account info
            if( response ){
                dispatch({type: SET_SPECIALITIES_FOR_FILTER, payload: response})
            }
            
        }catch(err){

            // show error
            dispatch({type: SET_FAILELD, payload: { message: err }})
            
        }finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

// add category
export const addSpecialitiesAction = (params: any): ThunkAction<void, AppState, null, Action<string>> => {
    return async(dispatch: any, getState: any) => {

        try{
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await addSpecialitiesApi(params).xhr;

            // set account info
            if( response ){
                dispatch({type: ADD_SPECIALITIES, payload: response})

                dispatch({type: SET_SUCCESS, payload: {success: true, message: 'Speciality added successfully'}})
                return response;
            }
            
        }catch(err){

            // show error
            dispatch({type: SET_FAILELD, payload: { message: err }})
            return false;
        }finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const addSpecialityWithCategoryAction = (params: any): ThunkAction<void, AppState, null, Action<string>> => {
    return async(dispatch: any, getState: any) => {

        try{
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await addSpecialityWithCategoryApi(params).xhr;

            // set account info
            if( response ){
                dispatch({type: SET_SUCCESS, payload: {success: true, message: 'Speciality added successfully'}})
                return response;
            }
            
        }catch(err){
            // show error
            dispatch({type: SET_FAILELD, payload: { message: err }})
            return false;
        }finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const deleteSpecialityByIdAction = (speciality_id: number): ThunkAction<void, AppState, null, Action<string>> => {
    return async(dispatch: any, getState: any) => {

        try{
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await deleteSpecialityByidApi(speciality_id).xhr;

            // set account info
            if( response ){
                dispatch({type: UPDATE_SPECIALITIES_WITH_CATEGORIES, payload: {id: speciality_id}})
                dispatch({type: SET_SUCCESS, payload: {success: true, message: 'Speciality deleted successfully !'}})
                return response;
            }
            
        }catch(err){
            // show error
            dispatch({type: SET_FAILELD, payload: { message: err }})
            return false;
        }finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

