import axios from "axios";
import { SectionsModal } from '../models'

export function getListSectionsApi() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, SectionsModal>({
        method: "get",
        url: `/sections`,
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function addSectionApi(data: FormData) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, SectionsModal>({
        method: "post",
        url: `/sections`,
        data
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function editSectionApi(id: string, params: any) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, SectionsModal>({
        method: "put",
        url: `/sections/${id}`,
        data: params
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function deleteSectionApi(id: string ,config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, SectionsModal>({
        method: "delete",
        url: `/sections/${id}`
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}
