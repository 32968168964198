import { combineReducers } from "redux";
import { ADD_LAWN_SERVICE_DISCOUNTS, ADD_LAWN_SERVICE_PRICES, LawnServiceActionType, LawnServiceState, SET_LAWN_SERVICE_DATA } from "../../../models";

const initialState: LawnServiceState = {}

export default combineReducers({
  data: (state: LawnServiceState = initialState, action: LawnServiceActionType) => {
    switch(action.type) {
      case SET_LAWN_SERVICE_DATA:
        state = action.payload;
        return state;
      case ADD_LAWN_SERVICE_PRICES:
        state = {
          ...state,
          ranges: [ ...state.ranges, ...action.payload.ranges ],
          prices: {
            ...state.prices,
            ...action.payload.prices,
          }
        }
        return state;
      case ADD_LAWN_SERVICE_DISCOUNTS:
        state = {
          ...state,
          discounts: [ ...state.discounts, ...action.payload.discounts ]
        }
        return state;
      default:
        return state;
    }
  }
})