import { combineReducers } from "redux";
import {
  // models
  SpecialtiesModal,

  // constants
  SET_SPECIALITIES,
  SpecialtiesActionType,
  SET_SPECIALITIES_FOR_FILTER,
  ADD_SPECIALITIES,
  SET_SPECIALITIES_WITH_CATEGORIES,
  UPDATE_SPECIALITIES_WITH_CATEGORIES,
} from "../../../models";
import { findIndex as lodashFindIndex } from "lodash";

const initialStateSpecialties: SpecialtiesModal[] = [];

export default combineReducers({
  data: (
    state: SpecialtiesModal[] = initialStateSpecialties,
    action: SpecialtiesActionType
  ) => {
    switch (action.type) {
      case SET_SPECIALITIES:
        return action.payload;

      case ADD_SPECIALITIES:
        return [...state, ...action.payload];

      default:
        return state;
    }
  },
  specialitiesForFilter: (
    state: SpecialtiesModal[] = initialStateSpecialties,
    action: SpecialtiesActionType
  ) => {
    switch (action.type) {
      case SET_SPECIALITIES_FOR_FILTER:
        return action.payload;

      default:
        return state;
    }
  },

  specialitiesWithCategories: (
    state: SpecialtiesModal[] = initialStateSpecialties,
    action: SpecialtiesActionType
  ) => {
    switch (action.type) {
      case SET_SPECIALITIES_WITH_CATEGORIES:
        return action.payload;
        
      case UPDATE_SPECIALITIES_WITH_CATEGORIES:
        const index = lodashFindIndex(state, { id: action.payload.id });
        if (index > -1) {
          state.splice(index, 1);
        }
        return state;

      default:
        return state;
    }
  },
});
