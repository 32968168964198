import { createCleaningServicePackagesApi, doCleaningServiceCheckoutApi, getCleaningServicesApi, updateCleaningServicePackageApi } from "../../../api";
import { SET_CLEANING_SERVICES, SET_FAILELD, START_LOADING_CLEANING, STOP_LOADING_CLEANING, ADD_CLEANING_SERVICES, SET_SUCCESS } from "../../../models";
import { startLoading, stopLoading } from "../common/loading";

export const getCleaningServices = (franchiseId: string) => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING_CLEANING)(dispatch, getState, null);
      const response = await getCleaningServicesApi(franchiseId).xhr;
      if(response) {
        dispatch({
          type: SET_CLEANING_SERVICES,
          payload: response});
      }
    } catch (error) {
      dispatch({type: SET_FAILELD, payload: {message: error} });
    } finally {
      stopLoading(STOP_LOADING_CLEANING)(dispatch, getState, null)
    }
  }
}

export const addCleaningServicePackage = ( data: {[key: string]: string | number}[] ) => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING_CLEANING)(dispatch, getState, null);
      const response = await createCleaningServicePackagesApi(data).xhr;
      console.log(response)
      if(response) {
        dispatch({
          type: ADD_CLEANING_SERVICES,
          payload: response
        });
      }
    } catch (error) {
      dispatch({type: SET_FAILELD, payload: {message: error} });
    } finally {
      stopLoading(STOP_LOADING_CLEANING)(dispatch, getState, null)
    }
  }
}

export const updateCleaningServicePackage = ( {data, success}: {data: {[key: string]: string | number}[]; success: () => void; } ) => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING_CLEANING)(dispatch, getState, null);
      const response = await updateCleaningServicePackageApi(data).xhr;
      console.log(response)
      if(response) {
        dispatch({type: SET_SUCCESS, payload: {success: true, message: 'Package updated successfully.'}});
        success();
      }
    } catch (error) {
      dispatch({type: SET_FAILELD, payload: {message: error} });
    } finally {
      stopLoading(STOP_LOADING_CLEANING)(dispatch, getState, null)
    }
  }
}

export const doCleaningServiceCheckoutAction = (data:any) => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING_CLEANING)(dispatch, getState, null);
      const response = await doCleaningServiceCheckoutApi(data).xhr;
      if(response) {
        return response
      }
      return false
    } catch (error) {
      dispatch({type: SET_FAILELD, payload: {message: error} });
      return false
    } finally {
      stopLoading(STOP_LOADING_CLEANING)(dispatch, getState, null)
    }
  }
}