import axios from "axios";

export function fileUploadApi(
  path: string,
  body: FormData,
  onUploadprogress: any
) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, any>({
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    url: `/upload/to/${path}`,
    data: body,
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      let percentage = Math.floor((loaded * 100) / total);
      onUploadprogress(percentage);
    },
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function fileUploadToServerApi(
  body: FormData,
  baseUrl: 'string'
) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, any>({
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    url: `/upload/to/server/${baseUrl}`,
    data: body
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}
