import {ThunkAction} from 'redux-thunk'
import {Action} from 'redux'
import {AppState} from '../../reducers'
import {
    //models
    FRANCHISE_NUMBER,

    // constants franchise
    SET_FRANCHISE,

    // constants alert
    SET_FAILELD,
} from "../../../models";

import {
    franchiseNumber
} from '../../../api';

export const franchiseNumberAction = (): any => {
    return async (dispatch: any, getState: any) => {

        try {
            const response = await franchiseNumber('').xhr;
            

            // set account info
            if (response) {
                
                dispatch({type: FRANCHISE_NUMBER, payload: response })
                return response;
            }

        } catch (err) {

            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})

        } finally {
        }
    };
};