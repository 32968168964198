import axios from "axios";


import {assignToRequest, opportunitiesParams, currentRequestState, requestFilters, requestSorts, hiredTrainerFilters} from "../models";
// import {
//     PackageState,
//     PackageParams
// } from "../models";

const hiredTrainerForReport: hiredTrainerFilters = {
    created_at: '',
    clientName: '',
    trainerName: '',
    additionalInfo: '',
    status: '',
    session_time: '',
    fromDate: '',
    toDate: '',
}


export function getAllRequestList(page: number, filters: requestFilters = {} as any, sorts: requestSorts = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "get",
        url: `/allrequest?page=${page}${filters.userName!=''?`&userName=${filters.userName}`:''}${filters.periodicity!=''?`&periodicity=${filters.periodicity}`:''}${filters.place!=''?`&place=${filters.place}`:''}${filters.prefer_sex!=''?`&prefer_sex=${filters.prefer_sex}`:''}${filters.created_at!=''?`&created_at=${filters.created_at}`:''}`,
        params: {
            sortColumn: sorts && sorts.sortColumn || '',
            sortOrder: sorts && sorts.sortOrder || '',
        }
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function getAllHiredTrainersApi(page: number, filters: hiredTrainerFilters = {} as any, sorts: requestSorts = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "get",
        url: `/all_hired_trainer?page=${page}${filters.clientName!=''?`&clientName=${filters.clientName}`:''}${filters.trainerName!=''?`&trainerName=${filters.trainerName}`:''}${filters.created_at!=''?`&created_at=${filters.created_at}`:''}${filters.additionalInfo!=''?`&additionalInfo=${filters.additionalInfo}`:''}${filters.status!=''?`&status=${filters.status}`:''}${filters.session_time!=''?`&session_time=${filters.session_time}`:''}${filters.client_id?`&client_id=${filters.client_id}`:''}`,
        params: {
            sortColumn: sorts && sorts.sortColumn || '',
            sortOrder: sorts && sorts.sortOrder || '',
            hire_status: filters.hire_status!='' && filters.hire_status || '',
        }
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function getAllConfirmedSessionsApi(page: number, filters: hiredTrainerFilters = {} as any, sorts: requestSorts = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "get",
        url: `/confirmed/session`,
        params: {
            page: page || 1,
        }
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function getAllHiredTrainersForReportApi(page: number, filters: hiredTrainerFilters = hiredTrainerForReport as any, sorts: requestSorts = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "get",
        url: `/all_hired_trainer_for_report?page=${page}${filters.clientName!=''?`&clientName=${filters.clientName}`:''}${filters.trainerName!=''?`&trainerName=${filters.trainerName}`:''}${filters.created_at!=''?`&created_at=${filters.created_at}`:''}${filters.additionalInfo!=''?`&additionalInfo=${filters.additionalInfo}`:''}${filters.status!=''?`&status=${filters.status}`:''}${filters.session_time!=''?`&session_time=${filters.session_time}`:''}${filters.fromDate!=''?`&fromDate=${filters.fromDate}`:''}${filters.toDate!=''?`&toDate=${filters.toDate}`:''}`,
        params: {
            sortColumn: sorts && sorts.sortColumn || '',
            sortOrder: sorts && sorts.sortOrder || '',
        }
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function getUserRequestList(userId:number,page: number, config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "get",
        url: `/request/list/${userId}?page=${page}`,
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function deleteRequest(id: string, config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, currentRequestState>({
        method: "delete",
        url: `/request/${id}`
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function getUserOpportunityList(userId:number,status:string, config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "get",
        url: `/requestOpportunities/${userId}${status?`?status[0]=${status}`:``}`,
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function getRequestOpportunitiesList(id: number, page: number, config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, opportunitiesParams>({
        method: "get",
        url: `/allrequest/opportunities/${id}?page=${page}`,
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function assignATrainerToARequest(body: assignToRequest, config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "post",
        data: body,
        url: `/requestOpportunities/create`,
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function deleteATrainerFromARequest(id: number, config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "delete",
        url: `/requestOpportunities/${id}`,
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function updateOpportunityStatusApi( oppId: number,status: any, config = {}) {

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "put",
        url: `/requestOpportunities/status/${oppId}`,
        data: status
    });

    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function createRequestAndHireTrainerApi( requestData: any, config = {}) {

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "post",
        url: `/create-and-hire-trainer`,
        data: requestData
    });

    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function createRequestAndHireTrainerAdminPanelApi( requestData: any, config = {}) {

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "post",
        url: `/create-and-hire-trainer-admin-panel`,
        data: requestData
    });

    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function postUserRequestApi(params: currentRequestState, config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<currentRequestState, null>({
        method: "post",
        url: `/request`,
        data: params,
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function postCreateRequestOpportunityApi(body:any){
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<currentRequestState, null>({
        method: "post",
        url: `/request-opportunities/create`,
        data: body,
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}