import axios from "axios";
import {
    DashboardState
} from "../models";

export function getDashboardData(config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, DashboardState[]>({
        method: "get",
        url: `/dashboard/info`,
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function getStateData(userId: string, packageId: string) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, DashboardState[]>({
        method: "get",
        //url: `/dashboard/customerStats/${userId}`,
        url: `/package/stats/${userId}/${packageId}`,
    
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function getCleaningPackageStatsData(userId: string, cleaningPackageId: string) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, DashboardState[]>({
        method: "get",
        url: `/cleaningPackage/stats/${userId}/${cleaningPackageId}`,
    
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function getStateTrainer(userId: string, data?: any) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, DashboardState[]>({
        method: "get",
        url: `/trainer/overview/stats/${userId}`
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}
