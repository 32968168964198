export const SET_LAWN_SERVICE_DATA = "SET_LAWN_SERVICE_DATA";
export const ADD_LAWN_SERVICE_PRICES = "ADD_LAWN_SERVICE_PRICES";
export const ADD_LAWN_SERVICE_DISCOUNTS = "ADD_LAWN_SERVICE_DISCOUNTS";

export interface LawnServiceState {
  ranges?: string[];
  prices?: { 
    [key: string]: {
        [key: string]: string | number
    } 
  };
  discounts?: {
    [key: string]: string | number
  }[];
}

interface SetLawnServices {
  type: typeof SET_LAWN_SERVICE_DATA;
  payload: LawnServiceState;
}

interface AddLawnServicesPrices {
  type: typeof ADD_LAWN_SERVICE_PRICES;
  payload: LawnServiceState;
}

interface AddLawnServicesDiscounts {
  type: typeof ADD_LAWN_SERVICE_DISCOUNTS;
  payload: LawnServiceState;
}

export type LawnServiceActionType = 
  | SetLawnServices
  | AddLawnServicesDiscounts
  | AddLawnServicesPrices;
