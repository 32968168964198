import axios from 'axios';

export const getUnlimitedSessionPackagesApi = (franchiseId:string, pageNo: number) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, string>({
      method: "get",
      url: `/unlimited-session-package/list/${franchiseId}?page=${pageNo}`,
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export const createUnlimitedSessionPackageApi = (data: {[key:string]: string|number}[]) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, string>({
      method: "post",
      url: `/unlimited-session-package/create`,
      data
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export const updateUnlimitedSessionPackageApi = (data: {[key:string]: string|number}[]) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, string>({
      method: "put",
      url: `/unlimited-session-package`,
      data
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export const updateUnlimitedSessionPackageStatusApi = (status: string, id: number) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, string>({
      method: "patch",
      url: `/unlimited-session-package/${status}/${id}`,
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}