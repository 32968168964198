import axios from "axios";
import {earningFilterModel, earningUpdateModel, sessionFilters, sessionSorts, sessionParams} from "../models/app/sessions";

var qs = require('qs');


export function getAllSessionList(status: string[] | any, filters: sessionFilters, sorts: sessionSorts, config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    let url = '/session';
    if (filters && filters.sessionDateFrom && filters.sessionDateTo) {
        url = `${url}`
    }
    let params: any = {
        status: status ? status : '',
        customer: filters && filters.customer || '',
        trainer: filters && filters.trainer || '',
        trainerId: filters && filters.trainerId || '',
        sessionDateFrom: filters && filters.sessionDateFrom || '',
        sessionDateTo: filters && filters.sessionDateTo || '',
        page: filters && filters.page || 1,
        sortColumn: sorts && sorts.sortColumn || '',
        sortOrder: sorts && sorts.sortOrder || '',
        clientIds: filters && filters.clientIds || [],
    }

    if(filters && (filters.lateCancel === true || filters.lateCancel === false)){
        params['lateCancel'] = filters.lateCancel
    }
    const xhr = axios.request<any, null>({
        method: "GET",
        url,
        params: params,
        paramsSerializer: params => {
            return qs.stringify(params)
        }
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function getAllSessionListWithoutPagination(status: string[] | any, filters: sessionFilters, sorts: sessionSorts, config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    let url = '/session/list/trainer';
    if (filters && filters.sessionDateFrom && filters.sessionDateTo) {
        url = `${url}?sessionDateFrom=${filters.sessionDateFrom}&sessionDateTo=${filters.sessionDateTo}`
    }
    let params: any = {
        status: status ? status : '',
        customer: filters && filters.customer || '',
        trainer: filters && filters.trainer || '',
        trainerId: filters && filters.trainerId || '',
        sessionDateFrom: filters && filters.sessionDateFrom || '',
        sessionDateTo: filters && filters.sessionDateTo || '',
        sortColumn: sorts && sorts.sortColumn || '',
        sortOrder: sorts && sorts.sortOrder || '',
        clientIds: filters && filters.clientIds || [],
    }

    if(filters && (filters.lateCancel === true || filters.lateCancel === false)){
        params['lateCancel'] = filters.lateCancel
    }
    const xhr = axios.request<any, null>({
        method: "GET",
        url,
        params: params,
        paramsSerializer: params => {
            return qs.stringify(params)
        }
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function getClientSessionListApi(clientId: number, config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<any, null>({
        method: "get",
        url: `/get/user-sessions/by/${clientId}`,
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function getOpportunitySessionList(opportunityId: number, status: string, page: number, config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<sessionParams[], null>({
        method: "get",
        url: `/session?requestOpportunityId=${opportunityId}&page=${page}`,

    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function getSessionById(id: number, config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<sessionParams, null>({
        method: "get",
        url: `/session/${id}`,
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function addNewSession(body: sessionParams, config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, any>({
        method: "post",
        data: body,
        url: `/session`,
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function checkForExpiredPackageApi(body: sessionParams, config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, any>({
        method: "get",
        url: `/check-for-expired-packages`,
        params: body,
        paramsSerializer: params => {
            return qs.stringify(params)
        }
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function updateSession(body: sessionParams, id: number, config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<sessionParams, sessionParams>({
        method: "put",
        data: body,
        url: `/session/${id}`,
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function updateSessionTrainer(body: sessionParams, id: number, config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<sessionParams, sessionParams>({
        method: "put",
        data: body,
        url: `session/update/trainer/${id}`,
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function deleteSession(id: number, config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "delete",
        url: `session/${id}`,
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function updateSessionEarning(id: number, body: earningUpdateModel, config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "put",
        url: `sessionEarning/${id}`,
        data: body
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function getSessionEarnings(earningFilter: earningFilterModel, config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "get",
        url: `sessionEarning`,
        params: {
            status: earningFilter.status,
            page: earningFilter.page,
            trainerId: earningFilter.trainerId,
            toDate:earningFilter.toDate,
            fromDate:earningFilter.fromDate
        },
        paramsSerializer: params => {
            return qs.stringify(params)
        }
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function getSessionEarningsForReport(earningFilter: earningFilterModel, config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "get",
        url: `sessionEarningforReport`,
        params: {
            toDate:earningFilter.toDate,
            fromDate:earningFilter.fromDate
        },
        paramsSerializer: params => {
            return qs.stringify(params)
        }
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function getDataForCategoryPackageSummaryApi(earningFilter: earningFilterModel, config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "get",
        url: `categoryPackageSummary`,
        params: {
            toDate:earningFilter.toDate,
            fromDate:earningFilter.fromDate,
            client_name: earningFilter.client_name,
            package_status: earningFilter.package_status,
            purchase_date: earningFilter.purchase_date,
            expire_date: earningFilter.expire_date,
            package_type: earningFilter.package_type,
            price_session: earningFilter.price_session,
            package_amount: earningFilter.package_amount,
            monthly_upfront: earningFilter.monthly_upfront,
            number_of_months: earningFilter.number_of_months,
            session_per_month: earningFilter.session_per_month,
            total_commited_session: earningFilter.total_commited_session,
            paid_session: earningFilter.paid_session,
            completed_session: earningFilter.completed_session,
            remain_paid_session: earningFilter.remain_paid_session,
            page: earningFilter.page
        },
        paramsSerializer: params => {
            return qs.stringify(params)
        }
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function getDataForCategoryPackageSummaryReportApi(earningFilter: earningFilterModel, config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "get",
        url: `categoryPackageSummaryforReport`,
        params: {
            toDate:earningFilter.toDate,
            fromDate:earningFilter.fromDate,
            client_id: earningFilter.client_id,
            reportFor: earningFilter.reportFor,
            page: earningFilter.page
        },
        paramsSerializer: params => {
            return qs.stringify(params)
        }
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function getDataForClientSessionDetailsApi(earningFilter: earningFilterModel, config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "get",
        url: `clientSessionDetailsforReport`,
        params: {
            toDate:earningFilter.toDate,
            fromDate:earningFilter.fromDate,
            page: earningFilter.page,
            status: earningFilter.status,
            late_cancel: earningFilter.late_cancel,
            trainer_name: earningFilter.trainer_name,
            client_name: earningFilter.client_name,
            session_date: earningFilter.session_date,
            session_id: earningFilter.session_id,
        },
        paramsSerializer: params => {
            return qs.stringify(params)
        }
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function getDataForClientSessionDetailsReportApi(earningFilter: earningFilterModel, config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "get",
        url: `clientSessionDetailsforExcelReport`,
        params: {
            toDate:earningFilter.toDate,
            fromDate:earningFilter.fromDate,
            page: earningFilter.page,
            client_id: earningFilter.client_id,
            reportFor: earningFilter.reportFor
        },
        paramsSerializer: params => {
            return qs.stringify(params)
        }
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function getEarningForGenerateAnalysisReportApi(earningFilter:earningFilterModel) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "get",
        url: `sessionEarningforAnalysisReport`,
        params: {
            toDate:earningFilter.toDate,
            fromDate:earningFilter.fromDate,
            name:earningFilter.name,
            remaining:earningFilter.remaining,
            category:earningFilter.category,
            expire:earningFilter.expire,
        },
        paramsSerializer: params => {
            return qs.stringify(params)
        }
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function getSessionEarningWithEarningCount(earningFilter: earningFilterModel, config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "get",
        url: `get-session-earning-with-earning-count`,
        params: {
            status: earningFilter.status,
            page: earningFilter.page,
            trainerId: earningFilter.trainerId,
            toDate:earningFilter.toDate,
            fromDate:earningFilter.fromDate
        },
        paramsSerializer: params => {
            return qs.stringify(params)
        }
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}



export function getFranchiseSessionEarnings(franchiseId:number,  config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "get",
        url: `franchiseSessionEarning`,
        params: {
            franchise_id:franchiseId
        },
        paramsSerializer: params => {
            return qs.stringify(params)
        }
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function getClientCountsApi(earningFilter: earningFilterModel, config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "get",
        url: `clientCounts`,
        params: {
            from_date: earningFilter.fromDate || '2022-01-01',
            to_date: earningFilter.toDate || new Date(),
            franchise_id: earningFilter.franchise_id,
            time_zone: earningFilter.time_zone,
        },
        paramsSerializer: params => {
            return qs.stringify(params)
        }
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function getClientCountsExcelApi(earningFilter: earningFilterModel, config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "get",
        url: `clientCountsExcel`,
        params: {
            from_date: earningFilter.fromDate,
            to_date: earningFilter.toDate,
            franchise_id: earningFilter.franchise_id,
            time_zone: earningFilter.time_zone,

        },
        paramsSerializer: params => {
            return qs.stringify(params)
        }
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}

