import axios from "axios";


export function createFormApi(formBody:any){
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "post",
        url: `/forms`,
        data: formBody
    });

    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function updateFormApi(id:number, formBody:any){
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "put",
        url: `/forms/${id}`,
        data: formBody
    });

    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function getFormApi(id:number){
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "get",
        url: `/forms/${id}`,
    });

    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function fetchFormApi(category_id:number){
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "get",
        url: `/forms/get-all/${category_id}`,
    });

    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function deleteFormApi(id:number){
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "delete",
        url: `/forms/${id}`,
    });

    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function updateFormsSortApi(formBody:any){
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "post",
        url: `/forms/update-forms-sort`,
        data: formBody
    });

    return {xhr, cancel: (message: string) => source.cancel(message)};
}