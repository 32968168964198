import { createUnlimitedSessionPackageApi, getUnlimitedSessionPackagesApi, updateUnlimitedSessionPackageApi, updateUnlimitedSessionPackageStatusApi } from "../../../api";
import { SET_FAILELD, START_LOADING, STOP_LOADING, ADD_UNLIMITED_SESSION_PACKAGE_DATA, SET_UNLIMITED_SESSION_PACKAGE_DATA, UPDATE_UNLIMITED_SESSION_PACKAGE_STATUS, DELETE_UNLIMITED_SESSION_PACKAGE, UPDATE_UNLIMITED_SESSION_PACKAGE_DATA, SET_UNLIMITED_SESSION_PACKAGE_PAGINATION } from "../../../models";
import { startLoading, stopLoading } from "../common/loading";

export const getUnlimitedSessionPackages = (franchiseId: string, pageNo: number) => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response: any = await getUnlimitedSessionPackagesApi(franchiseId, pageNo).xhr;
      if(response) {
        const {data, ...pagination} = response;
        dispatch({
          type: SET_UNLIMITED_SESSION_PACKAGE_DATA,
          payload: data
        });
        dispatch({
          type: SET_UNLIMITED_SESSION_PACKAGE_PAGINATION,
          payload: pagination,
        })
      }
    } catch (error) {
      dispatch({type: SET_FAILELD, payload: {message: error} });
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null)
    }
  }
}

export const addUnlimitedSessionPackage = ( data: {[key: string]: string | number}[], onSuccess: (message: string) => void ) => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await createUnlimitedSessionPackageApi(data).xhr;
      if(response) {
        dispatch({
          type: ADD_UNLIMITED_SESSION_PACKAGE_DATA,
          payload: response
        });
        onSuccess("Package added successfully.")
      }
    } catch (error) {
      dispatch({type: SET_FAILELD, payload: {message: error} });
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null)
    }
  }
}

export const updateUnlimitedSessionPackage = ( data: {[key: string]: string | number}[], onSuccess: (message: string) => void ) => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response: any = await updateUnlimitedSessionPackageApi(data).xhr;
      if(response) {
        dispatch({
          type: UPDATE_UNLIMITED_SESSION_PACKAGE_DATA,
          payload: response.data
        });
        onSuccess("Package updated successfully.")
      }
    } catch (error) {
      dispatch({type: SET_FAILELD, payload: {message: error} });
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null)
    }
  }
}

export const updateUnlimitedSessionPackageStatus = ( status: string, id: number, onSuccess: (message: string) => void ) => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await updateUnlimitedSessionPackageStatusApi(status, id).xhr;
      if(response) {
        dispatch({
          type: UPDATE_UNLIMITED_SESSION_PACKAGE_STATUS,
          payload: {id, status}
        });
        onSuccess("Package status updated successfully.")
      }
    } catch (error) {
      dispatch({type: SET_FAILELD, payload: {message: error} });
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null)
    }
  }
}

export const deleteUnlimitedSessionPackage = ( status: string, id: number, onSuccess: (message: string) => void ) => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await updateUnlimitedSessionPackageStatusApi(status, id).xhr;
      if(response) {
        dispatch({
          type: DELETE_UNLIMITED_SESSION_PACKAGE,
          payload: id
        });
        onSuccess("Package deleted successfully.")
      }
    } catch (error) {
      dispatch({type: SET_FAILELD, payload: {message: error} });
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null)
    }
  }
}