import { createLawnServiceDiscountsApi, createLawnServicePricesApi, getLawnPricesAndDiscountsApi, updateLawnServiceDiscountsApi, updateLawnServicePricesApi } from "../../../api";
import { SET_FAILELD, SET_SUCCESS, START_LOADING, STOP_LOADING, SET_LAWN_SERVICE_DATA, ADD_LAWN_SERVICE_DISCOUNTS, ADD_LAWN_SERVICE_PRICES } from "../../../models";
import { startLoading, stopLoading } from "../common/loading";

export const getLawnServices = (franchiseId: string) => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await getLawnPricesAndDiscountsApi(franchiseId).xhr;
      if(response) {
        dispatch({
          type: SET_LAWN_SERVICE_DATA,
          payload: response});
      }
    } catch (error) {
      dispatch({type: SET_FAILELD, payload: {message: error} });
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null)
    }
  }
}

export const addLawnServicePrices = ( data: {[key: string]: string | number}[] ) => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await createLawnServicePricesApi(data).xhr;
      if(response) {
        dispatch({
          type: ADD_LAWN_SERVICE_PRICES,
          payload: response
        });
      }
    } catch (error) {
      dispatch({type: SET_FAILELD, payload: {message: error} });
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null)
    }
  }
}

export const addLawnServiceDiscounts = ( data: {[key: string]: string | number}[] ) => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await createLawnServiceDiscountsApi(data).xhr;
      if(response) {
        dispatch({
          type: ADD_LAWN_SERVICE_DISCOUNTS,
          payload: response
        });
      }
    } catch (error) {
      dispatch({type: SET_FAILELD, payload: {message: error} });
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null)
    }
  }
}

export const updateLawnServicePrices = ( {data, success}: {data: {[key: string]: string | number}[]; success: () => void; } ) => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await updateLawnServicePricesApi(data).xhr;
      if(response) {
        dispatch({type: SET_SUCCESS, payload: {success: true, message: 'Packages updated successfully.'}});
        success();
      }
    } catch (error) {
      dispatch({type: SET_FAILELD, payload: {message: error} });
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null)
    }
  }
}

export const updateLawnServiceDiscounts = ( {data, success}: {data: {[key: string]: string | number}[]; success: () => void; } ) => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await updateLawnServiceDiscountsApi(data).xhr;
      if(response) {
        dispatch({type: SET_SUCCESS, payload: {success: true, message: 'Discounts updated successfully.'}});
        success();
      }
    } catch (error) {
      dispatch({type: SET_FAILELD, payload: {message: error} });
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null)
    }
  }
}