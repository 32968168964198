import { combineReducers } from "redux";
import { ADD_UNLIMITED_SESSION_PACKAGE_DATA, DELETE_UNLIMITED_SESSION_PACKAGE, SET_UNLIMITED_SESSION_PACKAGE_DATA, SET_UNLIMITED_SESSION_PACKAGE_PAGINATION, UPDATE_UNLIMITED_SESSION_PACKAGE_DATA, UPDATE_UNLIMITED_SESSION_PACKAGE_STATUS, UnlimitedSessionPackageActionType, UnlimitedSessionPackagePaginationActionType, UnlimitedSessionPackagePaginationState, UnlimitedSessionPackageState } from "../../../models";

const initialState: UnlimitedSessionPackageState[] = [];

const initialPaginationState: UnlimitedSessionPackagePaginationState  = {};

export default combineReducers({
  data: (state: UnlimitedSessionPackageState[] = initialState, action: UnlimitedSessionPackageActionType) => {
    switch(action.type) {
      case SET_UNLIMITED_SESSION_PACKAGE_DATA:
        state = action.payload;
        return state;
      case ADD_UNLIMITED_SESSION_PACKAGE_DATA:
        state = [ ...state,
          ...action.payload
        ]
        return state;
      case UPDATE_UNLIMITED_SESSION_PACKAGE_DATA:
        state = state.map(x => {
          if(x.id === action.payload.id){
            return action.payload
          } else return x
        })
        return state;
      case UPDATE_UNLIMITED_SESSION_PACKAGE_STATUS:
        state = state.map(x => {
          if(x.id === action.payload.id) {
            return { ...x, status: action.payload.status }
          } else return x
        })
        return state;
      case DELETE_UNLIMITED_SESSION_PACKAGE:
        state = state.filter(x=> x.id != action.payload)
        return state;
      default:
        return state;
    }
  },
  pagination: (state: UnlimitedSessionPackagePaginationState = initialPaginationState, action: UnlimitedSessionPackagePaginationActionType ) => {
    switch(action.type) {
      case SET_UNLIMITED_SESSION_PACKAGE_PAGINATION:
        state = action.payload;
        return state;
      default:
        return state;
    }
  }
})