import axios from "axios";
import {
  SignInState,
  ResponseAccount,
  SignUpState,
  ResponseSignUp,
  ForgotPasswordState,
  ResetPasswordState,
  AccountState,
  TrainerDetailsInfo,
  userData,
  UserFilters,
} from "../models";

var qs = require("qs");

export function postLogin(data = {}, config = {}) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<SignInState, ResponseAccount>({
    method: "post",
    url: "/users/signin",
    data,
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function postSignUp(data = {}, config = {}) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<SignUpState, ResponseSignUp>({
    method: "post",
    url: "/users/signup",
    data,
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function postUpdateProfile(id: number, data = {}, config = {}) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<SignUpState, any>({
    method: "post",
    url: `/users/profile/${id}`,
    data,
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function guestUserApi(data = {}, config = {}) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<{ role: string }, any>({
    method: "post",
    url: `/users/guestup`,
    data,
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function addAdditionalInformation(data = {}, config = {}) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<SignUpState, any>({
    method: "patch",
    url: `/profile`,
    data,
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function deleteTempUserApi(data = {}, config = {}) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, any>({
    method: "post",
    url: `/users/guestout`,
    data,
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function updateOsVersionApi(data = {}, userId="", config = {}) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, any>({
    method: "post",
    url: `users/update-os-type/${userId}`,
    data,
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function postForgotPass(data = {}, config = {}) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<ForgotPasswordState, ForgotPasswordState>({
    method: "post",
    url: "/users/forgotPassword",
    data,
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function postResetPass(data = {}, config = {}) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, ResetPasswordState>({
    method: "post",
    url: "/users/changePassword",
    data,
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function postUpdateTrainerDetailsInfo(
  id: number,
  data = {},
  config = {}
) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, TrainerDetailsInfo>({
    method: "post",
    url: `/trainer/details/${id}`,
    data,
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function getUserList(filters: UserFilters, config = {}) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, AccountState[]>({
    method: "get",
    url: `/users/list`,
    params: {
      role: filters.role,
      email: filters.email,
      fullname: filters.fullname,
      zipcode: filters.zipcode,
      searchable: filters.searchable,
      userPackage: filters.userPackage,
      registerDate: filters.registerDate,
      status: filters.status,
      franchise_id: filters.franchise_id,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function getUserNegativeSessionReport(config = {}) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, AccountState[]>({
    method: "get",
    url: `/users/negative/session/report`
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function getUserListWithPageApi(filters: UserFilters, config = {}) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, AccountState[]>({
    method: "get",
    url: `/users/list/with/page`,
    params: {
      role: filters.role,
      email: filters.email,
      fullname: filters.fullname,
      zipcode: filters.zipcode,
      searchable: filters.searchable,
      userPackage: filters.userPackage,
      registerDate: filters.registerDate,
      status: filters.status,
      page: filters.page
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function getTrainerListFilter(filters: UserFilters, config = {}) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, AccountState[]>({
    method: "get",
    url: `/trainer/list/filter`,
    params: {
      fullname: filters.fullname,
      role: filters.role
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function getUserById(id: number, config = {}) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, userData>({
    method: "get",
    url: `/users/profile/${id}`,
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function changeUserAvatar(id: number, avatar: FormData) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, any>({
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    url: `/users/profile/avatar/${id}`,
    data: avatar,
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function updateProfileAvatarApi(avatar: string) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, any>({
    method: "patch",
    url: `/update-profile-avatar`,
    data: {avatar},
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function changeUserStatus(id: number) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, any>({
    method: "get",
    url: `/users/status/${id}`,
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function updateTrainerUpdateDetailsInfo(
  id: number,
  data = {},
  config = {}
) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<SignUpState, any>({
    method: "post",
    url: `/trainer/details/${id}`,
    data,
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function setTrainerPayrateByCatApi(
  userId: number,
  trainerDataId: number,
  categoryId: number,
  payrateArrToSubmit: any,
  config = {}
) {
  console.log("Thisi si api", trainerDataId)
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, any>({
    method: "post",
    url: `/trainer/payrate/by/cat/${userId}/${trainerDataId}/${categoryId}`,
    data: {payrateArrToSubmit},
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

// Get Trainer Payrates by trainer id
export function getTrainerPayratesByIdApi(
  trainer_id: number,
) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, any>({
    method: "get",
    url: `/trainer/payrates/${trainer_id}`
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

// export function deleteUser(id: number) {
//   const CancelToken = axios.CancelToken;
//   const source = CancelToken.source();
//   const xhr = axios.request<null, any>({
//     method: "delete",
//     url: `users/delete/${id}?force=true`,
//   });
//   return { xhr, cancel: (message: string) => source.cancel(message) };
// }

export function deleteUser(id: number) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, any>({
    method: "post",
    url: `delete-user`,
    params: {
      user_id: id,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function checkUserProfileApi() {
  const xhr = axios.request<null, null>({
    method: "get",
    url: `/users/profile`,
  });
  return xhr;
}

export function resendVerificationMailApi(userEmail: string) {
  const xhr = axios.request<null, null>({
    method: "get",
    url: `/users/resendMail/${userEmail}`,
  });
  return xhr;
}

export function changeOldPasswordApi(oldPassword: string, newPassword: string) {
  const xhr = axios.request<null, null>({
    method: "post",
    url: "/users/changeOldPassword",
    data: { oldPassword, newPassword },
  });
  return xhr;
}

export function postOuathApi(provider: string, data: any) {
  const xhr = axios.request<null, ResponseAccount>({
    method: "post",
    url: `/oauth/${provider}`,
    data,
  });
  return xhr;
}
export function postSignInWithAppleApi(data: any) {
  const xhr = axios.request<null, ResponseAccount>({
    method: "post",
    url: `/sign-in-with-apple`,
    data,
  });
  return xhr;
}

export function updateFranchiseApi(data: any, useId: string) {
  const xhr = axios.request<null, ResponseAccount>({
    method: "post",
    url: `/users/profile/franchise/${useId}`,
    data,
  });
  return xhr;
}

export function getMaxRateAPI() {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, null>({
    method: "get",
    url: `/users/profile/`,
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function setMaxRateAPI(data: number) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, null>({
    method: "post",
    url: `/users/profile/`,
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function getUserPaymentsApi(id: number) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, null>({
    method: "get",
    url: `/stripe/user/payout/${id}`,
  });
  return xhr;
}

export function getTrainerTransactionsListAPI(id: number) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, null>({
    method: "get",
    url: `/stripe/user/transfers/${id}`,
  });
  return xhr;
}

export function getStripeUsl() {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, null>({
    method: "get",
    url: `/stripe/onboard`,
  });
  return xhr;
}

export function getUserPaymentsListApi(id: number) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, null>({
    method: "get",
    url: `/stripe/user/payments/${id}`,
  });
  return xhr;
}

export function getUserDisableApi(id:number){
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, null>({
    method: "get",
    url: `/get-user-disable/${id}`,
  });
  return xhr;
}

export function createStandardFranchiseAdminAPi(data:any){
  const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "post",
        url: `/create-standard-franchise-admin`,
        data
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function updateStandardFranchiseAdminAPi(data:any){
  const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "post",
        url: `/update-standard-franchise-admin`,
        data
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function getAccountLinksAPi(){
  const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "get",
        url: `/user/get-account-link`,
    });
    return xhr;
}

export function updateFranchiseKeysApi(data:any){
  const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "post",
        url: `/update-franchise-keys`,
        data
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function isFranchsieKeysUpdatedApi(){
  const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "get",
        url: `/is-franchise-keys-udpated`,
    });
    return xhr;
}

export function shouldExpirySessionApi(data:any){
  const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "post",
        url: `/users/should-expire-session`,
        data
    });
    return xhr;
}

export function updateTrainerPayoutApi(payoutTrainer:boolean, trainer_id:number) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, any>({
      method: "post",
      url: `/update-trainer-stripe-payout`,
      data: {payoutTrainer, trainer_id}
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function getTrainerAccountRequirementsAndStatusApi(){
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, any>({
       method: "get",
       url: '/trainer/get-stripe-account-requirements'
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function checkUserNameAvailabilityApi(email:string,config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, any>({
        method: "get",
        url: "/check-user-name-availability",
        params:{
            email:email
        },
        paramsSerializer: params => {
            return qs.stringify(params)
        }
    });

    return { xhr, cancel: (message: string) => source.cancel(message) };
}