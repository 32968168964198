import { currentRequestState } from "./requests";

/**
 * ACCOUNT ACTIONS
 */
// package
export const SET_PACKAGE = "SET_PACKAGE";
export const UPDATE_PACKAGE = "UPDATE_PACKAGE";
export const UPDATE_PACKAGE_MODE = "UPDATE_PACKAGE_MODE";
export const CLEAR_PACKAGE = "CLEAR_PACKAGE";
export const ADD_PACKAGE = "ADD_PACKAGE";
export const REMOVE_PACKAGE = "REMOVE_PACKAGE";
export const SET_PACKAGE_SERVICE_AND_PACKAGE_TYPE = "SET_PACKAGE_SERVICE_AND_PACKAGE_TYPE";
export const SET_CATEGORY_FOR_LOG_SESSION = "SET_CATEGORY_FOR_LOG_SESSION";
export const SET_PACKAGE_TYPE_FOR_LOG_SESSOIN = "SET_PACKAGE_TYPE_FOR_LOG_SESSOIN";

/////user packages
export const SET_USER_PACKAGE = "SET_USER_PACKAGE";
export const SET_USER_PACKAGE_TYPE = "SET_USER_PACKAGE_TYPE";
export const UPDATE_USER_PACKAGE = "UPDATE_USER_PACKAGE";
export const CANCEL_USERPACKAGE_SUBSCRIPTION = "CANCEL_USERPACKAGE_SUBSCRIPTION";

// current package
export const SET_PACKAGE_CURRENT = "SET_PACKAGE_CURRENT";
export const UPDATE_PACKAGE_CURRENT = "UPDATE_PACKAGE_CURRENT";
export const CLEAR_PACKAGE_CURRENT = "CLEAR_PACKAGE_CURRENT";

export const SET_USER_CURRENT_PACKAGE = "SET_USER_CURRENT_PACKAGE";
export const SET_ALL_PAYOUT = "SET_ALL_PAYOUT";
export const SET_URL_RECEIPT = "SET_URL_RECEIPT";

// for POST method
export interface PackageParams {
    id?: number,
    month: number;
    package_category?: number;
    quantity: number;
    quantity_free: number;
    discount_pay: number;
    payment_periodicity: 'one' | 'monthly';
    package_type: "custom" | 'usual';
    max_additional_person: number;
    coupon?: number;
    mode?: string;
    session_time: number;
    description: string;
    price_amount: number;
    price_currency_code: string;
    additional_person_amount: number;
    additional_person_currency_code: string;
    promo: string;
    status: boolean;
    Country : any;
    PackageType: any;
    package_name : string;
    arabic_package_name : string;
    arabic_description : string;
    PaymentLinks: any[],
    package_session_type: string;
    franchise_id: number;
    service_categories: any[];
    isDynamicQuantity:boolean;
    quantity_times:string;
    rollover_sessions: boolean;
}
export interface PaymentParams {
}

export interface UserPackage {
    id: number;
    month: number;
    quantity: number;
    quantity_free: number;
    max_additional_person: number;
    description: string;
    expire_time: string;
    status: string;
    categories?: any[]
};

export interface UserPackageState {
    id: number;
    package: PackageParams;
    month: number;
    quantity: number;
    quantity_free: number;
    max_additional_person: number;
    price_amount: number;
    additional_person_amount: number;
    status: string;
    description: string;
    expire_time: string;
    session_time: number;
    payment_amount: number;
    paid_months: number;
    upfront: boolean;
    auto_renew: boolean;
    subscription_id: string;
    rollover_sessions: boolean;
    bathrooms?: number;
    bedrooms?: number;
    estimated_hour?: string;
    flexible_with_time?: boolean;
    payment_mode?: string;
    price?: number;
    purchase_amount?: number;
    service_type?: string;
    sqft?: number;
    cleaning_service_package?: any;
}

export interface updatePackageModeState {
    id: number;
    mode: string
}

// for GET method
export interface PackageState extends PackageParams {
    id: number;
}
export interface PackageServiceAndPackageType {
    packageServiceCategories: any[];
    packagePackageType: any[]
}
export interface CategoryForLogSession {
    data: any[];
}
export interface PackageTypeForLogSession {
    data: any[];
}

export interface PaymentState extends PaymentParams {
}

export interface PackageListState {
    data: PackageState[];
    lastPage: number;
    page: number;
    perPage: number;
    total: number;
}

interface UpdatePackage {
    type: typeof UPDATE_PACKAGE;
    payload: PackageState;
}

interface UpdatePackageMode {
    type: typeof UPDATE_PACKAGE_MODE;
    payload: updatePackageModeState;
}

interface SetPackage {
    type: typeof SET_PACKAGE;
    payload: PackageState[];
}

interface SetPackageServiceAndPackageType {
    type: typeof SET_PACKAGE_SERVICE_AND_PACKAGE_TYPE;
    payload: PackageServiceAndPackageType;
}

interface SetCategoryForLogSessionType {
    type: typeof SET_CATEGORY_FOR_LOG_SESSION;
    payload: CategoryForLogSession;
}
interface SetPackageTypeForLogSessionType {
    type: typeof SET_PACKAGE_TYPE_FOR_LOG_SESSOIN;
    payload: PackageTypeForLogSession;
}

interface SetUserPackage {
    type: typeof SET_USER_PACKAGE;
    payload: UserPackageState[];
}

interface SetUserPackageType {
    type: typeof SET_USER_PACKAGE_TYPE;
    payload: UserPackageState[];
}

export interface UpdateUserPackage {
    type: typeof UPDATE_USER_PACKAGE;
    payload: UserPackage;
}

interface ClearPackage {
    type: typeof CLEAR_PACKAGE;
    payload: PackageState;
}

interface AddPackage {
    type: typeof ADD_PACKAGE;
    payload: PackageState;
}

interface RemovePackage {
    type: typeof REMOVE_PACKAGE;
    payload: PackageState;
}

interface PaymentPackage {
    type: typeof SET_ALL_PAYOUT;
    payload: PaymentParams;
}

interface ReceiptPackage {
    type: typeof SET_URL_RECEIPT;
    payload: PaymentParams;
}

export type PackageActionType =
    | UpdatePackage
    | SetPackage
    | ClearPackage
    | UpdatePackageMode
    | AddPackage
    | RemovePackage
    | SetPackageServiceAndPackageType
    | SetCategoryForLogSessionType
    | SetPackageTypeForLogSessionType;


interface UpdatePackageCurrent {
    type: typeof UPDATE_PACKAGE_CURRENT;
    payload: PackageState;
}

interface SetPackageCurrent {
    type: typeof SET_PACKAGE_CURRENT;
    payload: PackageState;
}

interface ClearPackageCurrent {
    type: typeof CLEAR_PACKAGE_CURRENT;
}

export type PackageCurrentActionType =
    | UpdatePackageCurrent
    | SetPackageCurrent
    | ClearPackageCurrent;



export type UserPackageActionType =
    | SetUserPackage
    | UpdateUserPackage
    | SetUserPackageType;

export type PaymentAllActionType =
    | PaymentPackage | ReceiptPackage;
