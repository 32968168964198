export const START_LOADING_CLEANING = "START_LOADING_CLEANING";
export const STOP_LOADING_CLEANING = "STOP_LOADING_CLEANING";
export const SET_CLEANING_SERVICES = "SET_CLEANING_SERVICES";
export const ADD_CLEANING_SERVICES = "ADD_CLEANING_SERVICES";
export interface CleaningServiceState {
  payment_modes?: string[];
  ranges?: string[];
  services?: { [key: string]: string[] };
  prices?: { [key: string]: {
    [key: string]: {
      [key: string]: {
        [key: string]: string | number
      }
    }
  } }
}

interface StartLoadingCleaning {
  type: typeof START_LOADING_CLEANING;
}

interface StopLoadingCleaning {
  type: typeof STOP_LOADING_CLEANING;
}

interface SetCleaningServices {
  type: typeof SET_CLEANING_SERVICES;
  payload: CleaningServiceState;
}

interface AddCleaningServices {
  type: typeof ADD_CLEANING_SERVICES;
  payload: CleaningServiceState;
}

export type CleaningServiceActionType = 
  | StartLoadingCleaning
  | StopLoadingCleaning
  | SetCleaningServices
  | AddCleaningServices;
